import { Link } from "react-router-dom";
import { connect } from "react-redux";
import React from "react";
import { MSG_LOGIN_GOOGLE } from "../messages";

import PageViewEvent from "../../Dashboard/PageViewEvent";

class SignInView extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let devModeLoginButton;
    // eslint-disable-next-line no-undef
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      devModeLoginButton = (
        <div className="col-sm-12 col-xs-12">
          <a className="btn loginBtn" href="/auth/developer" style={{ color: "black" }}>
            Dev mode login (this should not appear on production)
          </a>
        </div>
      );
    }

    return (
      <div className="container text-center wrap-content" id="login-form">
        <div className="row">
          {devModeLoginButton}
          <div className="col-xs-12 text-center">
            <Link to="/">
              <img
                src="/images/logo.png"
                style={{
                  height: "180px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </Link>
            <hr />
          </div>

          <div className="col-sm-12 col-xs-12">
            <a
              id="btn-login-google"
              className="btn loginBtn loginBtn--google"
              href="/auth/google_oauth2"
              onClick={this.handleGoogleLoginClick}
            >
              Sign in with Gmail
            </a>
          </div>
        </div>
        <PageViewEvent />
      </div>
    );
  }

  /**
   * Similar to handleFacebookLoginClick, we will stop click event if the web
   * is running inside webview, and send message to react native to login via
   * Google app.
   */
  handleGoogleLoginClick(event) {
    if (window.isNative) {
      event.preventDefault();
      window.postMessage(MSG_LOGIN_GOOGLE);
      return;
    }
  }
}

const SignIn = connect(state => {
  return state;
})(SignInView);

export default SignIn;
