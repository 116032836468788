import "regenerator-runtime/runtime";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

import "../../../client/stylesheets/mdb.css.scss";
import "../../../client/stylesheets/landing.css.scss";

import React from "react";
import { Provider } from "react-redux";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
const advancedMatchingPixel = {};
const optionPixels = {
  autoConfig: true,
  debug: false,
};

import App from "./App";
import { createStore } from "redux";

if (window.RAILS_ENV === "production") {
  ReactGA.initialize("UA-119405226-1");
  ReactPixel.init("221553698566333", advancedMatchingPixel, optionPixels);
} else {
  ReactGA.initialize("UA-119485534-1");
  ReactPixel.init("231084487711058", advancedMatchingPixel, optionPixels);
}

const store = createStore(() => {
  return {};
});

class Layout extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    );
  }
}

export default Layout;
